import axios from "@/utils/axios";

export const employeeList = 'user/list'
export const frontList = 'front/list'

export const EmployeeAdd = (data) => {
    return axios.post(`user/add`, data)
}
export const EmployeeEdit = (data) => {
    return axios.post(`user/edit`, data)
}


