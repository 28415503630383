<template>
    <el-dialog v-model="visible" :title="editorForm.id ? '编辑' : '新增'" width="500px" :before-close="BeforeClose">
        <el-form :model="editorForm" :rules="rulesForm" ref="ruleFormRef" label-width="80px">
            <el-form-item label="用户名" prop="name">
                <el-input v-model="editorForm.name"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="pass" v-if="!editorForm.id">
                <el-input type="password" v-model="editorForm.pass"></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="phone">
                <el-input v-model="editorForm.phone"></el-input>
            </el-form-item>
        </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="EditorSubmit(ruleFormRef)">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { inject, reactive, ref } from 'vue'

import { EmployeeAdd, EmployeeEdit } from '@/api/user'

export default {
    props: {
        title: {
            type: String,
            default: '新增商品'
        }
    },
    emits: ['submit'],
    setup(props, ctx) {
        const elplus = inject('elplus')
        const rulesForm = reactive({
            name: [{ required: true, message: '请填写用户名', trigger: 'blur' }],
            pass: [{ required: true, message: '请填写密码', trigger: 'blur' }],
            phone: [{ required: true, message: '请填写手机号', trigger: 'blur' }],
        })

        let visible = inject('employeeVisible')
        let editorForm = inject('editorForm')
        let ruleFormRef = ref(null)

        const methods = {
            BeforeClose () {
                visible.value = false
            },
            EditorSubmit () {
                const formEl = ruleFormRef.value;
                if (!formEl) return
                formEl.validate((valid) => {
                    if (valid) {
                        let loading = elplus.loading()
                        if (editorForm.value.id) {
                            EmployeeEdit({...editorForm.value}).then(res => {
                                loading.close()
                                if (!res.ret) ctx.emit('submit')
                            })
                        } else {
                            EmployeeAdd({...editorForm.value}).then(res => {
                                loading.close()
                                if (!res.ret) ctx.emit('submit')
                            })
                        }
                    }
                })
            }
        }

        return {
            ruleFormRef,
            rulesForm,
            visible,
            editorForm,
            ...methods
        }
    },
}
</script>

<style lang="scss" scoped>

</style>