<template>
    <div class="">
        <ATable :url="tableUrl" :columns="columns" :searchFormItems="searchFormItems" @operate-button-call-back="OperateButtonCallBack" ref="table">
            <el-button type="primary" @click="Editor()">新增</el-button>
        </ATable>
        <EmployeeEditEl @submit="EmployeeEditSubmit"/>
    </div>
</template>


<script>
import ATable from '@/components/table/index'
import EmployeeEditEl from './module/employeeEdit.vue'

import { employeeList, EmployeeEdit } from '@/api/user'
import { inject, provide, ref } from 'vue'

export default {
    components: {ATable, EmployeeEditEl},
    setup () {
        const tableUrl = ref(employeeList)
        const elplus = inject('elplus')

        const columns = ref([
            {prop: 'id', label: 'ID'},
            {prop: 'name', label: '用户名'},
            {prop: 'phone', label: '手机号'},
            {prop: 'operate', label: '操作', type: 'operate', width: 250, button: [
                {label: '编辑', dispose: 'Editor'},
                {label: '修改密码', dispose: 'EditorPass'},
                {label: '禁用', dispose: 'Forbidden'},
            ]},
        ])

        const searchFormItems = ref([
            {label: '登录名', prop: 'name', type: 'input'},
            {label: '手机号', prop: 'phone', type: 'input'}
        ])

        let editorForm = ref({})
        let employeeVisible = ref(false)
        let table = ref(null)
        provide('employeeVisible', employeeVisible)
        provide('editorForm', editorForm)

        const methods = {
            Editor (row = {}) {
                if (row.id) {
                    editorForm.value = {name: row.name, pass: row.pass, phone: row.phone, id: row.id}
                } else {
                    editorForm.value = {name: '', pass: '', phone: ''}
                }
                employeeVisible.value = !employeeVisible.value
            },
            OperateButtonCallBack ({row, fun}) {
                methods[fun](row)
            },
            Forbidden (row) {
                elplus.confirm(`确定${row.status == 1 ? '禁用' : '启用'}该用户吗？`).then(() => {
                    let loading = elplus.loading()
                    EmployeeEdit({id: row.id, status: row.status == 1 ? 2 : 1}).then(res => {
                        loading.close()
                        if (!res.ret) methods.EmployeeEditSubmit()
                    })
                })
            },
            EditorPass (row) {
                elplus.prompt('请输入密码', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputType: 'password',
                    inputErrorMessage: '请输入新密码',
                    inputValidator (value) {
                        return !!value
                    },
                }).then(({ value }) => {
                    let loading = elplus.loading()
                    EmployeeEdit({id: row.id, pass: value}).then(res => {
                        loading.close()
                        if (!res.ret) methods.EmployeeEditSubmit()
                    })
                })
            },
            EmployeeEditSubmit () {
                employeeVisible.value = false
                table.value.SearchSubmit()
            }
        }


        return {
            table,
            columns,
            tableUrl,
            searchFormItems,
            ...methods
        }
    }
}
</script>

<style lang="scss" scoped>

</style>